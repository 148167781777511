import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { stringManager } from "@/services/utilities/string-manager";
import router from "@/router";
import { loginManager } from "@/services/security/login-manager";

const URL_MENU = "/app/admin/menu";
const URL_MODULE = "/app/admin/module";
const URL_GENERATE_TRIAD_TEST = "/app/admin/training/generate-triad-test";
const URL_GENERATE_TRAINING_TEST = "/app/admin/training/generate-training-test";
const URL_USER_RESET_PASSWORD = "/app/admin/user/generate-password";
const URL_USER_LAST_PASSWORD_LINK = "/app/admin/user/last-password-link/";

class AdminManager {
  STUDEA_ADMIN_TRAINING = "STUDEA_ADMIN_TRAINING";
  STUDEA_ADMIN_INSTITUTION = "STUDEA_ADMIN_INSTITUTION";
  STUDEA_ADMIN_DEGREE = "STUDEA_ADMIN_DEGREE";
  STUDEA_ADMIN_CONTRACT_TYPE = "STUDEA_ADMIN_CONTRACT_TYPE";
  STUDEA_ADMIN_PLAN_CONTRACT = "STUDEA_ADMIN_PLAN_CONTRACT";
  STUDEA_ADMIN_TITLE_RNCP = "STUDEA_ADMIN_TITLE_RNCP";
  STUDEA_ADMIN_DESIGN = "STUDEA_ADMIN_DESIGN";
  STUDEA_ADMIN_TEMPLATE = "STUDEA_ADMIN_TEMPLATE";
  STUDEA_ADMIN_HELP = "STUDEA_ADMIN_HELP";
  STUDEA_ADMIN_DOCUMENT = "STUDEA_ADMIN_DOCUMENT";
  STUDEA_ADMIN_DATA_AGREEMENT = "STUDEA_ADMIN_DATA_AGREEMENT";
  STUDEA_ADMIN_HISTORY = "STUDEA_ADMIN_HISTORY";
  STUDEA_ADMIN_STATISTIC = "STUDEA_ADMIN_STATISTIC";
  STUDEA_ADMIN_EXPORT = "STUDEA_ADMIN_EXPORT";
  STUDEA_ADMIN_DUPLICATE = "STUDEA_ADMIN_DUPLICATE";
  STUDEA_ADMIN_ARCHIVE = "STUDEA_ADMIN_ARCHIVE";
  STUDEA_ADMIN_COMPONENT = "STUDEA_ADMIN_COMPONENT";
  STUDEA_ADMIN_MODULE = "STUDEA_ADMIN_MODULE";
  STUDEA_ADMIN_USER = "STUDEA_ADMIN_USER";
  STUDEA_ADMIN_ROLE = "STUDEA_ADMIN_ROLE";
  STUDEA_ADMIN_NEWS = "STUDEA_ADMIN_NEWS";

  getMenu() {
    return apiConnection.get(URL_MENU, [], true).then((response) => response);
  }

  resetUserPassword(id) {
    return apiConnection
      .get(URL_USER_RESET_PASSWORD + "/" + id, [], true)
      .then((response) => response);
  }

  putModule(datas, id) {
    return apiConnection
      .put(URL_MODULE + "/" + id, datas, true)
      .then((response) => response);
  }

  sortModule(id, index) {
    return apiConnection
      .put(URL_MODULE + "/sort/" + id + "/" + index, [], true)
      .then((response) => response);
  }

  list(alias, generic = false, params = {}) {
    return apiConnection
      .get(
        "/app/admin/" +
          (generic ? "generic/" : "") +
          encodeURI(alias) +
          "/list",
        params,
      )
      .then((response) => response);
  }

  form(
    alias,
    id = null,
    params = {},
    generic = false,
    search = false,
    unique = false,
  ) {
    return apiConnection
      .get(
        "/app/admin/" +
          (generic ? "generic/" : "") +
          encodeURI(alias) +
          (search ? "/search" : "") +
          "/" +
          (unique ? "unique-" : "") +
          "form" +
          (id ? "/" + id : ""),
        params,
      )
      .then((response) => response);
  }

  switchUser(id, studeaManager = false) {
    loginManager.storeInitialToken();
    return apiConnection
      .get(
        "/app" +
          (studeaManager ? "/studea-manager" : "/user") +
          "/switch/" +
          id,
      )
      .then((response) => {
        if (response.token && response.refreshToken) {
          router.push({
            name: "loginSwitch",
            query: {
              token: response.token,
              refreshToken: response.refreshToken,
            },
          });
        }
      });
  }

  remove(alias, id, tradGender = 1, generic = true) {
    const url =
      "/app/admin" +
      (generic ? "/generic" : "") +
      "/" +
      alias +
      "/delete/" +
      id;

    const tradPrefix = stringManager.toCamelCase(alias);

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", tradGender, {
            name: i18n.global
              .tc("admin." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", tradGender, {
            name: i18n.global
              .tc("admin." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.delete(url, {}).then((data) => {
              resolve(data);
              if (data.success) {
                notificationManager.showNotification(
                  data.hasError ? "error" : "success",
                  data.hasError
                    ? data.detail[0].message
                    : i18n.global.tc("global.delete.success", tradGender, {
                        name: i18n.global.tc(
                          "admin." + tradPrefix + ".title",
                          1,
                        ),
                      }),
                );
              } else if (!data.success && data.message) {
                notificationManager.showNotification("error", data.message);
              }
            });
          }
        });
    });
  }

  duplicate(alias, id, tradGender = 1, params = []) {
    const url = "/app/admin/" + alias + "/duplicate/" + id;

    const tradPrefix = stringManager.toCamelCase(alias);

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.copy.title", tradGender, {
            name: i18n.global
              .tc("admin." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.copy.sub_title", tradGender, {
            name: i18n.global
              .tc("admin." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.get(url, params).then((data) => {
              resolve(data);
              if (data.success) {
                notificationManager.showNotification(
                  data.hasError ? "error" : "success",
                  data.hasError
                    ? data.detail[0].message
                    : i18n.global.tc("global.copy.success", tradGender, {
                        name: i18n.global.tc(
                          "admin." + tradPrefix + ".title",
                          1,
                        ),
                      }),
                );
              } else if (!data.success && data.message) {
                notificationManager.showNotification("error", data.message);
              }
            });
          }
        });
    });
  }

  importForm(alias) {
    return apiConnection
      .get("/app/admin/" + encodeURI(alias) + "/import-form")
      .then((response) => response);
  }

  exportForm(alias) {
    return apiConnection
      .get("/app/admin/" + encodeURI(alias) + "/export-form")
      .then((response) => response);
  }

  postExport(alias, params) {
    return apiConnection
      .post("/app/admin/" + encodeURI(alias) + "/export-post", params)
      .then((response) => response);
  }

  generateTriadTest() {
    return apiConnection
      .get(URL_GENERATE_TRIAD_TEST)
      .then((response) => response);
  }

  generateTrainingTest() {
    return apiConnection
      .get(URL_GENERATE_TRAINING_TEST)
      .then((response) => response);
  }

  getLastPasswordLinkEmail(id) {
    return apiConnection
      .get(URL_USER_LAST_PASSWORD_LINK + id)
      .then((response) => response);
  }
}

export const adminManager = new AdminManager();
