import { apiConnection } from "@/services/api-connection";
import { fileManager } from "@/services/file/file-manager";
import { mainStore } from "@/main";

const URL_LIST = "/app/triad/list/";
const URL_ONE = "/app/triad/";
const URL_PDF = "/app/triad/pdf/";

class TriadManager {
  APPRENTICE_PROFILE = 1;
  TUTOR_PROFILE = 2;
  APPRENTICE_MASTER_PROFILE = 3;
  STUDEA_MANAGER_PROFILE = 4;
  loadTriadList = (id, archive) => {
    return apiConnection
      .get(URL_LIST + id, { archive: archive }, true)
      .then((response) => response);
  };
  loadOne = (id) => {
    return apiConnection
      .get(URL_ONE + id, {}, true)
      .then((response) => response);
  };

  pdf(id) {
    mainStore.fileDownloadRequest();
    return apiConnection.get(URL_PDF + id).then((data) => {
      fileManager.saveFile(data.filename, data.data, data.filetype);
      mainStore.fileDownloadSuccess();
    });
  }
}

export const triadManager = new TriadManager();
