<template>
  <div id="pdf" class="widget">
    <div class="widget-heading">
      <h2>{{ $t("actions") }}</h2>
    </div>
    <div class="widget-content">
      <button
        class="btn btn-primary mb-2 me-2 btnStyle"
        type="button"
        v-if="questionnaire.userCanExportPdf"
        @click="downloadPdf()"
      >
        <FontAwesomeIcon icon="fa-light fa-file-pdf" />
        {{ $t("downloadPdf") }}
      </button>
      <button
        class="btn btn-primary mb-2 me-2 btnStyle"
        type="button"
        @click="downloadPdf(1)"
      >
        <FontAwesomeIcon icon="fa-light fa-file-pdf" />
        {{ $t("downloadPdfModel") }}
      </button>
      <div
        v-if="hasToSign() && questionnaire.userCanExportPdf"
        class="line"
      ></div>
      <button
        v-if="hasToSign()"
        :class="
          'btn btn-success mb-2 me-2 ' + (shakeSignButton() ? '-active' : '')
        "
        type="button"
        :disabled="!shakeSignButton()"
        @click="save(3)"
      >
        <FontAwesomeIcon icon="fa-light fa-signature" />
        {{ $t("sign_action") }}
      </button>
      <button
        v-if="
          questionnaire.useComments &&
          hasToSign() &&
          (!questionnaire.userReply ||
            (questionnaire.userReply && questionnaire.userReply.status === 1))
        "
        class="btn btn-info mb-2 me-2"
        type="button"
        :disabled="loading"
        @click="save(2)"
      >
        <FontAwesomeIcon icon="fa-light fa-share" />
        {{ $t("sharedDraft") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "QuestionnaireControlBlockComponent",
  components: { FontAwesomeIcon },
  props: {
    isFullycompleted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(useQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
      loading: (store) => store.questionnaireLoading,
      model: (store) => store.model,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },
  mounted() {
    this.shakeSignButton();
  },
  methods: {
    downloadPdf(model = 0) {
      questionnaireManager.getPdf(this.triad.id, this.questionnaire.id, model);
    },
    save(replyStatus) {
      var message = "";
      this.$emit("isLoading", true);
      switch (replyStatus) {
        case 1:
          message = this.$t("draftSuccess");
          break;
        case 2:
          message = this.$t("sharedSuccess");
          break;
        case 3:
          message = this.$t("signedSuccess");
          break;
      }
      questionnaireManager
        .saveQuestionnaire(replyStatus, message, this.$t("signed_error"))
        .then(() => {
          this.$emit("isLoading", false);
        });
    },

    hasToSign() {
      return !this.questionnaire.message && this.questionnaire.userHasToSign;
    },

    shakeSignButton() {
      if (
        this.isFullycompleted ||
        (this.questionnaire.userHasToSign &&
          !this.questionnaire.userHasToComplete)
      ) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;

  svg {
    margin-right: 8px;
  }

  &.-active {
    animation: popup 500ms;
  }
}

.line {
  background: $light-grey;
  width: 100px;
  height: 1px;
  margin: 10px auto 15px auto;
}

@keyframes popup {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  33% {
    opacity: 1;
    transform: scale(1.15);
  }
  66% {
    opacity: 0.5;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
