<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-9 order-1 order-lg-0">
        <div class="chat-section">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="chat-system mb-4 mb-md-0">
                <div
                  :class="{
                    'user-list-box-show':
                      selectedMessage && !Object.keys(selectedMessage).length,
                  }"
                  class="user-list-box"
                >
                  <div class="search">
                    <svg
                      class="feather feather-search"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
                    </svg>
                    <input
                      v-model="searchMessage"
                      :placeholder="$t('page.search')"
                      class="form-control"
                      type="text"
                      @keyup="search"
                    />
                  </div>
                  <perfect-scrollbar
                    :options="{
                      wheelSpeed: 0.5,
                      swipeEasing: !0,
                      minScrollbarLength: 40,
                      maxScrollbarLength: 300,
                      suppressScrollX: true,
                    }"
                    class="people"
                  >
                    <div
                      v-for="message in messages"
                      :key="message.id"
                      :class="{
                        active:
                          message &&
                          selectedMessage &&
                          selectedMessage.id === message.id,
                      }"
                      class="person"
                      @click="openMessage(message)"
                    >
                      <div class="user-info">
                        <div class="f-body">
                          <div class="meta-info">
                            <span
                              :class="{
                                'text-primary':
                                  selectedMessage &&
                                  selectedMessage.id === message.id,
                              }"
                              class="user-name"
                            >
                              <FontAwesomeIcon icon="fa-light fa-message" />
                              {{ message.title }}</span
                            >
                            <span
                              :class="{
                                'text-primary':
                                  selectedMessage &&
                                  selectedMessage.id === message.id,
                              }"
                              class="user-meta-time"
                              >{{
                                message.date ? formatDate(message.date) : ""
                              }}</span
                            >
                          </div>
                          <span v-if="message.last" class="preview">
                            {{ message.last.sender.fullName }}<br />
                            {{
                              message.last.date
                                ? formatDate(message.last.date)
                                : ""
                            }}</span
                          >
                          <span
                            v-if="message.userNew"
                            class="notif badge badge-primary btnStyle"
                            >{{ message.userNew }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
                <div
                  :class="{
                    active: selectedMessage
                      ? Object.keys(selectedMessage).length
                      : null,
                  }"
                  class="chat-box"
                >
                  <div
                    :class="{
                      'd-none': selectedMessage
                        ? Object.keys(selectedMessage).length
                        : '',
                    }"
                    class="chat-not-selected"
                  >
                    <p>
                      <svg
                        class="feather feather-message-square"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
                        ></path>
                      </svg>
                      {{ $t("chat.select_message") }}
                    </p>
                  </div>

                  <div
                    v-if="
                      selectedMessage && Object.keys(selectedMessage).length
                    "
                    class="chat-box-inner h-100"
                  >
                    <div class="chat-meta-user chat-active">
                      <div v-if="!newMessage" class="current-chat-user-name">
                        <ChatRecipientsListComponent
                          :message="selectedMessage"
                        />
                      </div>
                      <div v-else class="w-100">
                        <div class="w-100 d-flex align-items-center px-3 py-2">
                          <div class="me-3">Sujet</div>
                          <InputTypeComponent :model="titleModel" />
                        </div>
                        <div class="w-100 d-flex align-items-center px-3 pb-2">
                          <div class="me-3">Destinataires</div>
                          <SelectTypeComponent
                            v-if="recipientsModel"
                            :model="recipientsModel"
                          />
                        </div>
                      </div>
                      <div v-if="!newMessage" class="current-chat-user-name">
                        <button
                          v-if="selectedMessage.sender.id === user.id"
                          class="btn btn-primary btnStyle p-2"
                          @click="remove"
                        >
                          <FontAwesomeIcon icon="fa-light fa-trash" />
                        </button>
                      </div>
                    </div>
                    <PerfectScrollbar
                      :options="{
                        wheelSpeed: 0.5,
                        swipeEasing: !0,
                        minScrollbarLength: 40,
                        maxScrollbarLength: 300,
                        suppressScrollX: true,
                      }"
                      :class="
                        'chat-conversation-box' +
                        (this.newMessage ? ' -new' : '')
                      "
                    >
                      <div
                        id="chat-conversation-box-scroll"
                        class="chat-conversation-box-scroll"
                      >
                        <div class="chat active-chat">
                          <ChatMessageComponent
                            v-if="selectedMessage.id"
                            :message="selectedMessage"
                          />
                          <ChatMessageComponent
                            v-for="message in selectedMessage.messages"
                            :key="message.id"
                            :message="message"
                          />
                        </div>
                      </div>
                    </PerfectScrollbar>
                    <div class="chat-footer chat-active">
                      <div class="chat-input">
                        <div
                          v-if="fileModel.vars.value.id"
                          class="chat-attachment"
                        >
                          <div class="badge badge-primary btnStyle">
                            <FontAwesomeIcon icon="fa-light fa-paperclip" />
                            {{ fileModel.vars.value.originalName }}
                            <FontAwesomeIcon
                              class="ms-1 file-remove"
                              icon="fa-light fa-xmark"
                              @click="removeFile"
                            />
                          </div>
                        </div>
                        <div class="chat-form">
                          <EditorTypeComponent
                            :inline="true"
                            :model="editorModel"
                          />
                          <div class="chat-actions">
                            <div class="d-none">
                              <FileTypeComponent :model="fileModel" />
                            </div>
                            <button
                              class="btn btn-primary btnStyle ms-2 ms-md-0 mb-2"
                              type="submit"
                              @click="browse"
                            >
                              <FontAwesomeIcon icon="fa-light fa-paperclip" />
                            </button>
                            <button
                              class="btn btn-primary btnStyle ms-2 ms-md-0"
                              type="submit"
                              @click="send"
                              :disabled="!disableButton()"
                            >
                              <FontAwesomeIcon icon="fa-light fa-paper-plane" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BlockLoader :loading="loading" />
        </div>
      </div>
      <div
        v-if="!isMobile || !Object.keys(selectedMessage).length"
        class="col-lg-3 order-0 order-lg-1"
      >
        <div class="sticky">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            @new="startNewMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import EditorTypeComponent from "@/components/form/EditorTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FileTypeComponent from "@/components/form/FileTypeComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import SelectTypeComponent from "@/components/form/SelectTypeComponent.vue";
import { messageManager } from "@/services/message/message-manager";
import ChatMessageComponent from "@/components/chat/ChatMessageComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { userManager } from "@/services/security/user-manager";
import ChatRecipientsListComponent from "@/components/chat/ChatRecipientsListComponent.vue";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { useMessageStore } from "@/store/message/message";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "ChatView",
  components: {
    ChatRecipientsListComponent,
    InputTypeComponent,
    ChatMessageComponent,
    SelectTypeComponent,
    ActionsBlock,
    BlockLoader,
    FileTypeComponent,
    FontAwesomeIcon,
    EditorTypeComponent,
    PerfectScrollbar,
  },
  data() {
    return {
      pageTitle: "",
      selectedMessage: [],
      filtered_contact_list: [],
      loading: false,
      newMessage: false,
      recipients: [],
      searchMessage: "",
      datas: [],
      isStudeaManagerRoute: false,
      recipientsModel: {
        vars: {
          block_prefixes: ["", "choice"],
          label: false,
          expanded: false,
          multiple: true,
          name: "recipients",
          value: "",
          required: true,
          id: "recipients",
          choices: this.recipients,
          attr: {
            class: "w-100",
            "data-hide-error": 1,
          },
        },
      },
      editorModel: {
        vars: {
          block_prefixes: ["", "text", "textarea", "zama_tiny_mce_type"],
          label: false,
          name: "new_message",
          value: "",
          required: true,
          attr: {
            placeholder: this.$t("chat.placeholder"),
            "data-mcetype": "simple",
            "data-hide-error": 1,
          },
          id: "new_message",
        },
      },
      titleModel: {
        vars: {
          block_prefixes: ["", "text"],
          label: false,
          name: "new_title",
          value: "",
          required: true,
          attr: {
            placeholder: this.$t("chat.placeholder_subject"),
          },
          id: "new_title",
        },
      },
      fileModel: {
        vars: {
          block_prefixes: ["", "file"],
          label: false,
          name: "new_message_file",
          value: "",
          required: false,
          id: "new_message_file",
          conf_name: this.isStudeaManager() ? "training_course" : "triad",
          conf_path: "message",
        },
      },
      actions: [],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useMessageStore, {
      messages: (store) => store.messages,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useMainStore, {
      isMobile: (store) => store.isMobile,
    }),
  },
  watch: {
    $route() {
      if (this.$route.path.search("new") !== -1) {
        this.startNewMessage();
      } else if (this.$route.params.id) {
        this.retrieveCurrentMessageFromRoute();
        this.newMessage = false;
      } else {
        this.selectedMessage = [];
        this.newMessage = false;
      }
    },
    messages() {
      if (this.$route.params.id) {
        this.retrieveCurrentMessageFromRoute();
      }
    },
    triad() {
      if (
        this.$route.path.search("new") !== -1 &&
        this.triad &&
        this.triad.id
      ) {
        this.startNewMessage();
      }
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_MESSAGE,
      );
    },
    trainingCourse() {
      if (this.$route.path.search("new") !== -1) {
        this.startNewMessage();
      }
    },
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_MESSAGE,
    );
    this.initDatas();
    this.initActions();
  },
  mounted() {
    if (this.$route.path.search("new") !== -1) {
      this.startNewMessage();
    }
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    openMessage(message) {
      const updatedUserNewMessage = this.user.userNewMessage - message.userNew;
      this.$router.push({
        name: this.isStudeaManagerRoute
          ? "studeaManagerMessagingShow"
          : "showMessage",
        params: { id: message.id },
      });
      this.selectedMessage = message;
      this.selectedMessage.userNew = 0;
      this.setUserNewMessage(updatedUserNewMessage);
      this.messageOpen(message.id);
      setTimeout(this.scrollToBottom, 200);
    },

    isStudeaManager() {
      return !!this.$route.matched.find(
        (item) => item.name === "studeaManagerMessaging",
      );
    },

    initActions() {
      this.actions = [
        {
          translation: "chat.create_message",
          icon: "plus",
          type: "primary",
          action: "new",
          cfaStyle: true,
        },
      ];

      if (this.isStudeaManager()) {
        this.actions.push({
          translation: "chat.moderation",
          icon: "user-magnifying-glass",
          type: "primary",
          cfaStyle: true,
          actionType: "router",
          action: "studeaManagerMessagingModeration",
          code: studeaManagerManager.STUDEA_MANAGER_MESSAGE_MODERATION,
          access: accessManager.ALL,
        });
      }
    },

    initDatas() {
      this.loading = true;
      this.isStudeaManagerRoute = !!this.$route.matched.find(
        (item) => item.name === "studeaManagerLayout",
      );
      this.getMessages().then(() => {
        this.loading = false;
      });
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    getProfileByName(profileName) {
      return userManager.getProfileByName(profileName);
    },

    initRecipients() {
      if (
        this.newMessage &&
        (!this.recipientsModel.vars.choices ||
          (this.recipientsModel.vars.choices &&
            !Object.keys(this.recipientsModel.vars.choices).length))
      ) {
        let res = [];
        if (
          (this.triad && this.triad.id) ||
          this.$route.params.trainingCourseId
        ) {
          this.loading = true;
          messageManager
            .loadRecipients(
              this.$route.params.trainingCourseId
                ? this.$route.params.trainingCourseId
                : null,
            )
            .then((data) => {
              if (data && Object.keys(data).length) {
                Object.entries(data).forEach(([profile, recipients]) => {
                  let choices = {};
                  if (recipients) {
                    Object.values(recipients).forEach((recipient) => {
                      choices[recipient.id] = {
                        label: recipient.fullName,
                        value: recipient.id,
                        data: {
                          id: recipient.id,
                          profile: this.getProfileByName(profile),
                        },
                      };
                    });
                    res.push({
                      choices: choices,
                      label: this.$t(profile),
                    });
                  }
                });
              }
              this.loading = false;
              this.recipientsModel.vars.choices = res;
            });
        }
      }
      return false;
    },

    retrieveCurrentMessageFromRoute() {
      if (Object.keys(this.messages).length && this.$route.params.id) {
        this.selectedMessage = this.messages.find(
          (message) => message.id === parseInt(this.$route.params.id),
        );
        this.scrollToBottom();
        setTimeout(this.scrollToBottom, 200);
      } else {
        this.selectedMessage = [];
      }
    },
    browse() {
      const input = document.getElementById(this.fileModel.vars.id);
      input.click();
    },
    messageOpen(id) {
      this.selectedMessage.notif = 0;
      this.setMessageOpen(id);
    },
    removeFile() {
      this.fileModel.vars.value = "";
    },
    send() {
      this.loading = true;
      let message = {
        text: this.editorModel.vars.value,
        mother: { id: this.selectedMessage.id },
        title: this.titleModel.vars.value,
        messageFile: this.fileModel.vars.value.id
          ? { id: this.fileModel.vars.value.id }
          : null,
        messageRecipients: null,
        owner: true,
        notification: false,
        notificationRecipient: false,
        trainingCourse: { id: this.trainingCourse.id },
        loading: true,
      };

      if (this.newMessage) {
        let recipients = [];
        Object.values(this.recipientsModel.vars.value).forEach((recipient) => {
          recipients.push({ recipient: recipient.data });
        });
        this.recipientsModel.vars.error = !recipients.length;
        message.messageRecipients = recipients;
      }

      if (!this.newMessage || message.messageRecipients.length) {
        this.sendMessage(message).then((res) => {
          if (!res.hasError) {
            const message = res.responses[0].object;

            if (!this.selectedMessage.messages) {
              this.selectedMessage["messages"] = [];
            }

            Object.values(this.selectedMessage.messages).push(message);
            this.editorModel.vars.value = "";
            this.fileModel.vars.value = "";
            this.scrollToBottom();
            this.getMessages().then(() => {
              if (this.newMessage) {
                this.$router.push({
                  name: this.isStudeaManagerRoute
                    ? "studeaManagerMessagingShow"
                    : "showMessage",
                  params: { id: message.id },
                });
                this.newMessage = false;
                this.selectedMessage = message;
                this.recipientsModel.vars.value = "";
              }
              this.loading = false;
            });
          }
        });
      }
    },

    search() {
      this.datas = Object.values(this.triad.messages).filter((message) => {
        return message.sender.toLowerCase().includes(this.searchMessage);
      });
    },

    scrollToBottom() {
      setTimeout(() => {
        const element = document.querySelector(".chat-conversation-box");
        if (element) {
          element.scrollTop = element.scrollHeight;
          element.behavior = "smooth";
        }
      });
    },

    remove() {
      notificationManager
        .showAlert(
          "warning",
          this.$t("chat.delete_title"),
          this.$t("chat.delete_confirm"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;
            messageManager
              .removeMessage(this.selectedMessage.id)
              .then((data) => {
                if (data) {
                  notificationManager.showNotification(
                    "success",
                    this.$t("chat.delete_success"),
                  );
                  this.getMessages().then(() => {
                    this.$router.push({
                      name: this.isStudeaManagerRoute
                        ? "studeaManagerMessaging"
                        : "MODULE_STUDEA_MESSAGE",
                    });
                    this.loading = false;
                  });
                }
              });
          }
        });
    },

    startNewMessage() {
      this.isStudeaManagerRoute = !!this.$route.matched.find(
        (item) => item.name === "studeaManagerLayout",
      );
      this.$router.push({
        name: this.isStudeaManagerRoute
          ? "studeaManagerMessagingNew"
          : "newMessage",
      });
      this.titleModel.vars.value = "";
      this.newMessage = true;
      this.initRecipients();
      this.selectedMessage = {
        title: "",
        sender: "",
        owner: true,
        profile: "apprentice",
        date: null,
        message: null,
        report: false,
        notif: 0,
        file: null,
        mother: null,
      };
    },

    disableButton() {
      if (this.editorModel.vars.value) {
        return true;
      } else return !!this.fileModel.vars.value;
    },

    ...mapActions(useMessageStore, [
      "getMessages",
      "setMessageOpen",
      "sendMessage",
    ]),
    ...mapActions(useUserStore, ["setUserNewMessage"]),
  },
};
</script>

<style lang="scss" scoped>
.chat-attachment {
  height: 28px;
}

.file-remove,
.btnStyle {
  cursor: pointer;
}

.chat-section {
  position: relative;
}

.notif {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.current-chat-user-name {
  svg {
    height: 17px;
    width: 17px;
  }
}

@include up-to-md {
  .user-list-box {
    position: relative;
    left: initial;
    width: 100%;
    max-width: 100%;
    display: none;

    .people {
      height: 100%;
    }
  }

  .user-list-box-show {
    left: initial;
    display: block;
  }

  .chat-system {
    display: block;
  }

  .chat-box {
    display: none;

    &.active {
      display: block;
      height: 100%;

      .chat-not-selected {
        display: none;
      }
    }
  }
}
</style>
