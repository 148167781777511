<template>
  <div v-if="active" class="chatbot" :class="{ open: open }">
    <button
      :class="'chatbotToggle' + (open ? ' open' : '')"
      v-tooltip="{ title: 'Léa, votre assistante IA', position: 'left' }"
      @click="openChatBot"
      ref="toggle"
    >
      <img
        class="chatbot__logo"
        :class="{ show: !buttonHover && (!animatedLogo || open) }"
        src="/assets/images/symbole_small.png"
      />
      <div
        class="loader-app chatbotLoader"
        :class="{ show: buttonHover || (animatedLogo && !open) }"
      >
        <div class="purple1 -fade--1"></div>
        <div class="purple2 -fade--2"></div>
        <div class="green -fade--3"></div>
        <div class="red -fade--4"></div>
      </div>
    </button>
    <div class="blockStyle2 h-100">
      <div class="chatbot__heading">
        <img class="chatbot__logo show" src="/assets/images/illustration.png" />
        <h2 class="textStyle mt-3">LEA</h2>
        <p class="textStyle">Votre assistante IA</p>
        <button class="chatbot__close" @click="closeChatBot">
          <FontAwesomeIcon icon="fa-light fa-xmark" />
        </button>
      </div>
      <div ref="chatMessages" class="chatbot__messages">
        <div
          v-for="message in messages"
          :key="message.id"
          :class="'chatbot__row ' + (message.writer === 'me' ? 'me' : 'bot')"
        >
          <div class="chatbot__message">
            <div v-if="Object.keys(user).length" class="chatbot__writer">
              {{ message.writer === "me" ? user.firstName : "Léa" }}
            </div>
            <div
              class="chatbot__text"
              :class="{ 'text-typing': message.writer !== 'me' }"
              v-html="message.content"
            ></div>
          </div>
        </div>
        <div v-if="loading" class="chatbot__row bot">
          <div class="chatbot__message loading">
            <div class="chatbot__typing">
              <div class="dot-typing"></div>
            </div>
          </div>
        </div>
      </div>
      <form class="chatbot__form" @submit="post">
        <InputTypeComponent :model="model" />
        <button class="chatbot__submit" type="submit">
          <FontAwesomeIcon icon="fa-light fa-arrow-up" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { mapActions } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useChatbotStore } from "@/store/chatbot/chatbot";

export default {
  name: "ChatBotComponent",
  computed: {
    ...mapState(useChatbotStore, {
      messages: (store) => store.messages,
      loading: (store) => store.loading,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  data() {
    return {
      model: {
        vars: {
          block_prefixes: ["", "text"],
          name: "user_chatbot",
          label: false,
          id: "user_chatbot",
          value: "",
          required: false,
          attr: {
            placeholder: "Posez votre question...",
            autocomplete: "off",
          },
          row_attr: {
            class: "m-0",
          },
        },
      },
      submit: {},
      open: false,
      active: true,
      interval: null,
      intervalLength: 10,
      animatedLogo: false,
      intervalLogo: null,
      intervalLogoLength: 5000,
      buttonHover: false,
    };
  },
  mounted() {
    this.setLogoInterval();
    const backdrop = document.getElementById("backdrop");
    backdrop.classList.remove("show");
    backdrop.addEventListener("click", () => {
      this.closeChatBot();
    });
    this.$refs.toggle.addEventListener("mouseover", () => {
      this.buttonHover = true;
    });
    this.$refs.toggle.addEventListener("mouseout", () => {
      this.buttonHover = false;
    });
  },
  methods: {
    post(e) {
      e.preventDefault();
      const $this = this;
      const container = $this.$refs.chatMessages;
      if (this.model.vars.value) {
        const message = {
          content: this.model.vars.value,
          writer: "me",
        };
        $this.storeMessage(message);
        $this.chatbotRequest(message).then((res) => {
          $this.scrollToBottom(container);
          $this.write(res);
        });
        this.scrollToBottom(container);

        this.model.vars.value = "";
      }
    },

    setLogoInterval() {
      if (this.intervalLogo) {
        clearInterval(this.intervalLogo);
      }
      this.intervalLogo = setInterval(() => {
        this.animatedLogo = !this.animatedLogo;
      }, this.intervalLogoLength);
    },

    write(message) {
      let start = 0;
      let end = 1;
      let max = message.response.length;

      this.interval = setInterval(() => {
        const sliced = message.response.substring(start, end);
        const content = message.content.concat(sliced);
        this.writeContent(message.id, content);
        start++;
        end++;
        this.scrollToBottom(this.$refs.chatMessages);
        if (start >= max) {
          this.cancelWriting();
        }
      }, this.intervalLength);
    },

    cancelWriting() {
      clearInterval(this.interval);
      this.interval = null;
    },

    openChatBot() {
      this.open = true;
      const container = this.$refs.chatMessages;
      container.scrollTop = container.scrollHeight;
      const backdrop = document.getElementById("backdrop");
      backdrop.classList.add("show");
      const input = document.getElementById("user_chatbot");
      input.focus();
    },

    closeChatBot() {
      this.open = false;
      const backdrop = document.getElementById("backdrop");
      backdrop.classList.remove("show");
    },

    scrollToBottom(element) {
      setTimeout(function () {
        element.scroll({ top: element.scrollHeight, behavior: "smooth" });
      }, 5);
    },

    ...mapActions(useChatbotStore, [
      "storeMessage",
      "chatbotSuccess",
      "chatbotRequest",
      "writeContent",
    ]),
  },
  components: {
    InputTypeComponent,
  },
};
</script>

<style lang="scss">
.chatbotToggle {
  width: 70px;
  height: 64px;
  position: fixed;
  bottom: 85px;
  right: 400px;
  font-weight: 700;
  background: $white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;
  border-radius: $radius 0 0 $radius;
  border: 2px solid $lighter-grey;
  color: $blue;
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: space-between;
  z-index: -1;
  transition: all 300ms ease-in-out;

  img {
    width: 50px;
  }
}

.chatbot {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  width: 400px;
  transition: all 300ms ease-in-out;
  z-index: 9999;
  transform: translateX(100%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;

  &.open {
    transform: translateX(0);
  }

  input {
    border-radius: 50px;
    border: 1px solid var(--primary-color);
    height: 50px;
    max-height: 50px;
    padding-right: 50px;
  }

  &__heading {
    background: $white;
    height: 192px;
    width: 100%;
    padding: 15px;
    position: relative;
    text-align: center;
    border-bottom: 1px dashed var(--primary-color);

    h2 {
      height: auto;
      margin-top: 0;
    }
  }

  &__logo {
    width: 90px;
    position: relative;
    top: 2px;
    opacity: 0;
    visibility: visible;
    transition: all 300ms ease-in-out;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 10px;
    background: none;
    border: none;
    color: $black;
    font-size: 22px;
  }

  &__writer {
    font-weight: 700;
  }

  &__form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    padding: 15px;
    border-top: 1px dashed var(--primary-color);
  }

  &__messages {
    padding: 25px 15px;
    height: calc(100% - 272px);
    overflow: scroll;
    background: $white;
    position: relative;
  }

  &__row {
    display: flex;
    justify-content: flex-end;

    &.bot {
      justify-content: flex-start;
    }
  }

  &__message {
    background: var(--primary-color);
    color: $white;
    border-radius: 10px;
    padding: 10px;
    width: 80%;

    &.loading {
      max-width: 45px;
      height: 25px;
    }
  }

  &__typing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__row + &__row {
    margin-top: 10px;
  }

  &__submit {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    width: 100%;
    height: 100%;
    max-width: 25px;
    max-height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    color: $white;
  }
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
  position: relative;
  left: -9999px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: $white;
  color: $white;
  box-shadow:
    9990px 0 0 0 $white,
    9999px 0 0 0 $white,
    10008px 0 0 0 $white;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  16.667% {
    box-shadow:
      9990px -5px 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  33.333% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  50% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px -5px 0 0 $white,
      10008px 0 0 0 $white;
  }
  66.667% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  83.333% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px -5px 0 0 $white;
  }
  100% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
}
</style>
