<template>
  <div class="dashboard__categories">
    <div
      v-for="(category, index) in categories"
      :key="category.id"
      :aria-expanded="!index"
      :class="
        'dashboard__category widget category-' +
        category.id +
        (!index ? ' on' : '')
      "
      :style="
        '--max-width:' +
        200 *
          (category.originalQuestionnaires &&
          category.originalQuestionnaires.length
            ? category.originalQuestionnaires.length
            : 1) +
        'px'
      "
    >
      <h3 :id="'heading' + category.id" class="dashboard__header">
        <button
          class="dashboard__category__title"
          @click="toggle(category.id, 'category-', true)"
        >
          {{ category.title }}
          <FontAwesomeIcon icon="fa-light fa-chevron-right"></FontAwesomeIcon>
        </button>
      </h3>
      <div class="dashboard__questionnaires">
        <div
          v-for="questionnaire in category.originalQuestionnaires"
          :key="questionnaire.id"
          class="dashboard__questionnaires__item"
        >
          <div class="dashboard__questionnaires__title">
            {{ questionnaire.title }}
          </div>
          <div
            v-if="type === 'questionnaire'"
            :class="
              'dashboard__questionnaires__actions actions-' + questionnaire.id
            "
          >
            <button
              class="btnStyle btn-success dashboard__questionnaires__actions__button"
              @click="toggle(questionnaire.id, 'actions-')"
            >
              <FontAwesomeIcon
                class="icon"
                icon="fa-light fa-chevron-up"
              ></FontAwesomeIcon>
            </button>
            <div class="dashboard__questionnaires__actions__container">
              <button
                class="btn btn-success btnStyle me-2"
                v-tooltip="{
                  title: $t(
                    'studea_manager.dashboard.questionnaire.unlock_all_questionnaires',
                  ),
                }"
                @click="unlockColumnQuestionnaires(questionnaire)"
              >
                <FontAwesomeIcon icon="fa-light fa-lock"></FontAwesomeIcon>
              </button>
              <button
                class="btn btn-success btnStyle"
                v-tooltip="{
                  title: $t(
                    'studea_manager.dashboard.export.all_promotion_replies',
                  ),
                }"
                @click="
                  exportAllQuestionnaireReplies(questionnaire.id, category.id)
                "
              >
                <FontAwesomeIcon icon="fa-light fa-download"></FontAwesomeIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "AccordionHeadlineQuestionnaireComponent",
  props: {
    type: {
      type: String,
      required: true,
    },
    categories: {
      type: [],
      required: true,
    },
  },
  methods: {
    unlockColumnQuestionnaires(questionnaire) {
      studeaManagerManager
        .unlockAllReplies(questionnaire.id, this.$route.params.trainingCourseId)
        .then(() => {
          Object.values(questionnaire.dashboardData).forEach((reply) => {
            if (reply.apprentice.icon === "lock") {
              reply.apprentice.icon = "lock-open";
              reply.apprentice.unlocked = true;
            }
            if (reply.tutor.icon === "lock") {
              reply.tutor.icon = "lock-open";
              reply.tutor.unlocked = true;
            }
            if (reply.apprenticeMaster.icon === "lock") {
              reply.apprenticeMaster.icon = "lock-open";
              reply.apprenticeMaster.unlocked = true;
            }
          });
        });
    },

    exportAllQuestionnaireReplies(item) {
      console.log(
        "Exporter l'ensemble des réponses de la promotion pour le questionnaire n°" +
          item,
      );
    },

    toggle(item, name, isMultipleElement) {
      studeaManagerManager.toggle(item, name, isMultipleElement);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__header {
    width: var(--max-width);
    margin-bottom: 0;
  }

  &__category {
    transition: all 300ms ease-in-out;
    width: 130px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: fit-content;
    overflow: hidden;
    border: 1px solid #e0e6ed;

    &:last-child {
      margin-right: 0;
    }

    &__title {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      background-color: var(--primary-color);
      color: $white;
      border-radius: 6px;
      border: none;
      height: 280px;
      width: 130px;
      font-size: 0.6rem;
      transition: all 300ms ease-in-out;

      svg {
        transition: all 300ms ease-in-out;
        transform: rotate(0);
        margin-left: 20px;
      }
    }

    &.on {
      width: var(--max-width);

      .dashboard__category__title {
        width: var(--max-width);
        height: 150px;
        font-size: 0.875rem;

        svg {
          transform: rotate(180deg);
        }
      }

      .dashboard__questionnaires {
        height: 130px;
        width: var(--max-width);
      }
    }
  }

  &__questionnaires {
    display: flex;
    background-color: $lighter-grey;
    position: relative;
    transition: all 300ms ease-in-out;
    height: 0;
    width: 130px;
    overflow: hidden;

    &__title {
      padding: 20px;
    }

    &__item {
      overflow: hidden;
      position: relative;
      width: 200px;
      border-right: 1px solid #e0e6ed;
    }

    &__actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: translateY(63px);
      transition: transform 200ms ease-in-out;

      &.on {
        transform: translateY(0);

        .dashboard__questionnaires__actions__button {
          .icon {
            transform: rotate(180deg);
          }
        }
      }

      &__button {
        width: 100%;
        border: none;
        font-size: 0.7rem;
        padding: 2px 5px;
        border-radius: 6px 6px 0 0;

        .icon {
          transform: rotate(0);
          transition: all 300ms ease-in-out;
        }
      }

      &__container {
        display: flex;
        padding: 10px;
        background-color: $white;
      }
    }
  }
}
</style>
