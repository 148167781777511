export default {
  categories: [
    {
      id: 123,
      title: "EVALUATIONS EN ENTREPRISE",
      questionnaires: [
        {
          id: 586,
          title:
            "Environnement de travail : à compléter en vue du premier entretien",
          openingDate: "2024-01-01",
          closingDate: "2024-06-30",
        },
      ],
    },
    {
      id: 133,
      title: "FICHE DE MISSIONS",
      questionnaires: [
        {
          id: 55,
          title: "FICHE DETAIL DE LA MISSION - à compléter avant fin décembre",
          openingDate: "2024-01-01",
          closingDate: "2024-06-30",
        },
      ],
    },
    {
      id: 150,
      title: "EVALUATIONS EN ENTREPRISE",
      questionnaires: [
        {
          id: 822,
          title: "Evaluation en entreprise période 1 - sept.-déc.",
          openingDate: "2024-01-01",
          closingDate: "2024-06-30",
        },
        {
          id: 844,
          title: "Evaluation en entreprise période 2 - jan.-avr.",
          openingDate: "2024-01-01",
          closingDate: "2024-06-30",
        },
        {
          id: 102,
          title: "Evaluation en entreprise période 3 - mai-août",
          openingDate: "2024-01-01",
          closingDate: "2024-06-30",
        },
      ],
    },
    {
      id: 134,
      title: "FICHE DE MISSIONS",
      questionnaires: [],
    },
  ],
  crews: [
    {
      id: 23,
      title: "groupe 1",
      triads: [
        {
          id: 55,
          apprentice: {
            id: 38,
            fullName: "John Doe",
          },
          tutor: {
            id: 11,
            fullName: "Pierre Dupont",
          },
          apprenticeMaster: {
            id: 12,
            fullName: "Henri Durand",
          },
          leaManager: {
            id: 66,
            fullName: "Paul Hello",
          },
          contract: {
            startDate: "2023-09-01",
            endDate: "2024-06-25",
          },
        },
        {
          id: 62,
          apprentice: {
            id: 39,
            fullName: "Damien Abc",
          },
          tutor: {
            id: 14,
            fullName: "Albert Azerty",
          },
          apprenticeMaster: {
            id: 15,
            fullName: "Michel Ytreza",
          },
          leaManager: {
            id: 66,
            fullName: "Paul Hello",
          },
          contract: {
            startDate: "2023-09-01",
            endDate: "2024-06-25",
          },
        },
      ],
    },
    {
      id: 6,
      title: "groupe 2",
      triads: [
        {
          id: 56,
          apprentice: {
            id: 16,
            fullName: "Henri 123",
          },
          tutor: {
            id: 17,
            fullName: "James Bond",
          },
          apprenticeMaster: {
            id: 18,
            fullName: "Marion Dfgh",
          },
          leaManager: {
            id: 66,
            fullName: "Paul Hello",
          },
          contract: {
            startDate: "2023-09-01",
            endDate: "2024-06-25",
          },
        },
      ],
    },
    {
      id: 8,
      title: "groupe 3",
      triads: [
        {
          id: 57,
          apprentice: {
            userId: 16,
            fullName: "Henri 123",
          },
          tutor: {
            userId: 17,
            fullName: "James Bond",
          },
          apprenticeMaster: {
            userId: 18,
            fullName: "Marion Dfgh",
          },
          leaManager: {
            userId: 66,
            fullName: "Paul Hello",
          },
          contract: {
            startDate: "2023-09-01",
            endDate: "2024-06-25",
          },
        },
      ],
    },
    {
      id: 10,
      title: "groupe 4",
      triads: [
        {
          id: 58,
          apprentice: {
            userId: 16,
            fullName: "Henri 123",
            lastName: "123",
          },
          tutor: {
            userId: 17,
            fullName: "James Bond",
            lastName: "Bond",
          },
          apprenticeMaster: {
            userId: 18,
            fullName: "Marion Dfgh",
            lastName: "Dfgh",
          },
          leaManager: {
            userId: 66,
            fullName: "Paul Hello",
            lastName: "Hello",
          },
          contract: {
            startDate: "2023-09-01",
            endDate: "2024-06-25",
          },
        },
      ],
    },
  ],
  replies: [
    {
      replyId: 900,
      triadId: 56,
      questionnaireId: 822,
      note: "6/10",
    },
    {
      replyId: 950,
      triadId: 62,
      questionnaireId: 822,
      note: "8/10",
    },
    {
      replyId: 960,
      triadId: 55,
      questionnaireId: 586,
      note: "10/10",
    },
    {
      replyId: 902,
      triadId: 62,
      questionnaireId: 586,
      note: "4/10",
    },
  ],
};
