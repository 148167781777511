<template>
  <ScrollProgressBarComponent
    :dataLength="progressBarLength"
    :step="progress"
    :title="$t('question')"
    v-if="displayProgressBar"
  />
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-9 order-xl-2 order-1">
        <QuestionnaireBlockComponent
          :key="key"
          :isLoading="isLoading"
          :pdfSrc="pdfSrc"
          @focused="focused"
          @isLoading="loading"
          @saveDraft="saveDraft"
        />
      </div>
      <div
        class="col-xl-3 col-md-6 position-relative order-2 order-xl-3 order-lg-3"
      >
        <div class="sticky">
          <QuestionnaireControlBlockComponent
            @downloadPdf="downloadPdf"
            @isLoading="loading"
            :isFullycompleted="isQuestionnaireFullycompleted"
          />
          <QuestionnaireSignatoryBlockComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useMeta } from "@/composables/use-meta";
import ScrollProgressBarComponent from "@/components/utilities/ScrollProgressBarComponent.vue";
import QuestionnaireBlockComponent from "@/components/questionnaire/QuestionnaireBlockComponent.vue";
import QuestionnaireControlBlockComponent from "@/components/questionnaire/QuestionnaireControlBlockComponent.vue";
import QuestionnaireSignatoryBlockComponent from "@/components/questionnaire/QuestionnaireSignatoryBlockComponent.vue";
import { useTriadStore } from "@/store/triad/triad";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useUserStore } from "@/store/user/user";

export default {
  name: "QuestionnaireView",
  components: {
    QuestionnaireSignatoryBlockComponent,
    QuestionnaireControlBlockComponent,
    QuestionnaireBlockComponent,
    ScrollProgressBarComponent,
  },
  data() {
    return {
      progress: 1,
      invertColor: false,
      scrollTop: 0,
      isLoading: false,
      progressBarLength: 0,
      displayProgressBar: true,
      key: 0,
      pdfSrc: "",
      isQuestionnaireFullycompleted: false,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
    }),
    ...mapState(useUserStore, {
      userProfile: (store) => store.userProfile,
    }),
  },
  created() {
    useMeta({ title: this.questionnaire.title });
  },
  updated() {
    useMeta({ title: this.questionnaire.title });
  },
  beforeRouteLeave() {
    this.clearEvents();
  },
  mounted() {
    this.getQuestionnaire();
    this.initEvents();
    this.initProgressBar();
  },
  watch: {
    "questionnaire.questions"() {
      this.setProgressBarLength();
      this.key++;
      this.setDisplayProgressBar();
    },
    triad() {
      this.key++;
    },
    userProfile() {
      this.setProgressBarLength();
    },
  },
  methods: {
    downloadPdf() {
      this.$refs.export.save(this.questionnaire.title);
    },

    clearEvents() {
      window.removeEventListener("focus", this.refreshQuestionnaire);
      window.removeEventListener("scroll", this.initProgressBar);
    },
    initEvents() {
      window.addEventListener("focus", this.refreshQuestionnaire);
      window.addEventListener("scroll", this.initProgressBar);
    },
    loading(value) {
      this.isLoading = value;
    },

    refreshQuestionnaire() {
      // this.getQuestionnaire();
    },

    setDisplayProgressBar() {
      this.displayProgressBar =
        (this.questionnaire.userHasToComplete ||
          this.questionnaire.userHasToSign) &&
        !this.questionnaire.userHasSigned;
    },

    focused(id) {
      this.inputFocused = id;
    },
    getQuestionnaire(refresh = false) {
      const html = document.querySelector("html");
      this.scrollTop = html.scrollTop;

      this.loading(true);
      this.fetchQuestionnaire({
        questionnaire: this.$route.params.questionnaireId,
        triad: this.$route.params.triadId,
        refresh: refresh,
      }).then(() => {
        this.key++;
        this.setProgressBarLength();
        this.setPdfSrc();
        window.scrollTo({ top: this.scrollTop, behavior: "instant" });
        this.loading(false);
      });
    },

    setPdfSrc() {
      if (this.questionnaire.base64Pdf) {
        this.pdfSrc = this.questionnaire.base64Pdf;
      }
    },

    setProgressBarLength() {
      let length = 0;
      if (this.questionnaire && this.questionnaire.questions) {
        this.questionnaire.questions.forEach((question) => {
          let userHasToCompleted = false;
          if (question.questions && question.questions.length) {
            question.questions.forEach((item) => {
              if (item.access && item.access.includes(this.userProfile)) {
                userHasToCompleted = true;
              }
            });
            if (userHasToCompleted) {
              length++;
            }
          }

          if (question.access && question.access.includes(this.userProfile)) {
            length++;
          }
        });
      }
      this.progressBarLength = length;
    },

    initProgressBar() {
      this.setProgressBarLength();
      const blocks = [];
      const totalHeight = document.getElementById("content").clientHeight;

      if (this.questionnaire && this.questionnaire.questions) {
        let userHasToComplete;
        this.questionnaire.questions.forEach((question) => {
          if (question.questions) {
            question.questions.forEach((item) => {
              if (item.access && item.access.includes(this.userProfile)) {
                userHasToComplete = true;
              }
            });
            if (userHasToComplete) {
              blocks.push(
                document.getElementById("questionnaire-" + question.id),
              );
            }
          }
        });

        const userQuestions = this.questionnaire.questions.filter(
          (item) =>
            item.access &&
            item.access.length &&
            item.access.includes(this.userProfile),
        );
        userQuestions.forEach((item) => {
          blocks.push(document.getElementById("questionnaire-" + item.id));
        });
      }

      if (
        document.getElementById("content").clientHeight >
        window.innerHeight * 1.1
      ) {
        for (let i = 0; i < blocks.length; i++) {
          const coords = blocks[i].getBoundingClientRect();
          if (coords.y - window.screen.height / 2 < 0) {
            this.progress = i + 1;

            this.isQuestionnaireFullycompleted =
              this.progress === this.progressBarLength;
          }
        }
      }

      if (
        window.scrollY < (window.screen.height * 10) / 100 &&
        document.getElementById("content").clientHeight >
          window.innerHeight * 1.1
      ) {
        this.progress = 1;
      }

      if (window.innerHeight + window.scrollY + 10 >= totalHeight) {
        this.progress = this.progressBarLength;
        this.isQuestionnaireFullycompleted = true;
      }
    },

    saveDraft(model) {
      let answers = 0;
      model.forEach((item) => {
        if (item.arrayValue.answers && item.arrayValue.answers.length) {
          answers++;
        }

        if (
          document.getElementById("content").clientHeight <
          window.innerHeight * 1.1
        ) {
          this.progress = answers;
          this.isQuestionnaireFullycompleted =
            answers === this.progressBarLength;
        }
      });
    },

    ...mapActions(useQuestionnaireStore, ["fetchQuestionnaire"]),
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-top: 10px;
}
</style>
