<template>
  <h1 class="">
    <span class="fw-normal fs-6">{{ $t("new_password") }}</span
    ><br />
    <span class="brand-name textStyle">{{ name }}</span>
  </h1>
  <div v-if="message" class="badge badge-danger me-1 w-100">
    {{ message }}
  </div>
  <form class="text-start">
    <div id="password-field" class="field-wrapper input">
      <svg
        class="feather feather-lock svgStyle"
        fill="none"
        height="24"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
      </svg>
      <input
        v-model="password"
        :placeholder="$t('password')"
        class="form-control"
        type="password"
        @input="checkPassword"
      />
      <div v-if="passwordError" class="invalid-feedback d-block">
        {{ passwordError }}
      </div>
    </div>
    <div id="password-field" class="field-wrapper input">
      <svg
        class="feather feather-lock svgStyle"
        fill="none"
        height="24"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
      </svg>
      <input
        v-model="password2"
        :placeholder="$t('password_confirm')"
        class="form-control"
        type="password"
        @input="checkPassword2"
      />
      <div v-if="password2Error" class="invalid-feedback d-block">
        {{ password2Error }}
      </div>
    </div>
    <div class="field-wrapper text-center">
      <button
        class="btn btn-primary blockStyle"
        type="submit"
        @click="confirm($event)"
      >
        {{ $t("confirm") }}
      </button>
    </div>
  </form>
</template>

<script>
import { mapState } from "pinia";
import { loginManager } from "@/services/security/login-manager";
import { formValidator } from "@/services/form/form-validator";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "NewPasswordView",
  components: {},
  data() {
    return {
      message: "",
      password: "",
      passwordError: "",
      password2: "",
      password2Error: "",
      hash: "",
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      name: (store) => store.name,
    }),
  },
  created() {
    this.hash = this.$route.params.hash;
  },
  methods: {
    checkPassword() {
      if (!this.password) {
        this.passwordError = this.$t("requiredField");
      } else if (!formValidator.validPassword(this.password)) {
        this.passwordError = this.$t("password_not_valid");
      } else {
        this.passwordError = "";
      }

      return !!this.passwordError;
    },

    checkPassword2() {
      if (!this.password2) {
        this.password2Error = this.$t("requiredField");
      } else if (this.password !== this.password2) {
        this.password2Error = this.$t("password_error");
      } else if (!formValidator.validPassword(this.password2)) {
        this.password2Error = this.$t("password_not_valid");
      } else {
        this.password2Error = "";
      }

      return !!this.password2Error;
    },

    confirm(e) {
      e.preventDefault();
      if (!this.checkPassword2() && !this.checkPassword()) {
        loginManager.newPassword(this.password2, this.hash).then(() => {
          this.$router.push({ name: "Login" });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
